import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DifferentlyStyledFirstNumberPipe } from './differently-styled-first-number.pipe';
import { DifferentlyStyledLastNumberPipe } from './differently-styled-last-number.pipe';
import { DifferentlyStyledTextPartPipe } from './differently-styled-text-part.pipe';
import { DifferentlyStyledTextSplitPipe } from './differently-styled-text-split.pipe';


@NgModule({
  declarations: [
    DifferentlyStyledFirstNumberPipe,
    DifferentlyStyledLastNumberPipe,
    DifferentlyStyledTextPartPipe,
    DifferentlyStyledTextSplitPipe
  ],
  exports: [
    DifferentlyStyledFirstNumberPipe,
    DifferentlyStyledLastNumberPipe,
    DifferentlyStyledTextPartPipe,
    DifferentlyStyledTextSplitPipe
  ]
})
export class DifferentlyStyledStringParserPipesModule { }
