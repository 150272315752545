import { Pipe, PipeTransform } from '@angular/core';
import { KurzDifferentlyStyledValueObject } from '../../kurz-differently-styled-value.types';
import { differentlyStyledLastNumber } from '../functions/nth-number.function';


@Pipe({
  name: 'differentlyStyledLastNumber'
})
export class DifferentlyStyledLastNumberPipe implements PipeTransform {

  transform(value: string, numberTriple?: KurzDifferentlyStyledValueObject, remainderTriple?: KurzDifferentlyStyledValueObject): KurzDifferentlyStyledValueObject[] {
    return differentlyStyledLastNumber(value, numberTriple, remainderTriple);
  }

}
